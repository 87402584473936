@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 47.4% 11.2%;
   
      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;
   
      --popover: 0 0% 100%;
      --popover-foreground: 222.2 47.4% 11.2%;
   
      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
   
      --card: 0 0% 100%;
      --card-foreground: 222.2 47.4% 11.2%;
   
      --primary: 222.2 47.4% 11.2%;
      --primary-foreground: 210 40% 98%;
   
      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;
   
      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;
   
      --destructive: 0 100% 50%;
      --destructive-foreground: 210 40% 98%;
   
      --ring: 215 20.2% 65.1%;
   
      --radius: 0.5rem;
    }
   
    .dark {
      --background: 224 71% 4%;
      --foreground: 213 31% 91%;
   
      --muted: 223 47% 11%;
      --muted-foreground: 215.4 16.3% 56.9%;
   
      --accent: 216 34% 17%;
      --accent-foreground: 210 40% 98%;
   
      --popover: 224 71% 4%;
      --popover-foreground: 215 20.2% 65.1%;
   
      --border: 216 34% 17%;
      --input: 216 34% 17%;
   
      --card: 224 71% 4%;
      --card-foreground: 213 31% 91%;
   
      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 1.2%;
   
      --secondary: 222.2 47.4% 11.2%;
      --secondary-foreground: 210 40% 98%;
   
      --destructive: 0 63% 31%;
      --destructive-foreground: 210 40% 98%;
   
      --ring: 216 34% 17%;
   
      --radius: 0.5rem;
    }

  @font-face {
    font-family: "Sportsbet-Icons";
    src: url('./assets/fonts/Sportsbet-Icons.woff') format('woff');
  }
  @font-face {
    font-family: "Cairoli Now";
    src: url("./assets/fonts/CairoliNow-Medium.ttf");
  }
}

.scroller {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scroller::-webkit-scrollbar {
  display: none;
}

.horse-icon::before {
  content: "\E058";
  -webkit-font-smoothing: antialiased;
}

.tick-icon::before {
  content: "\E038";
}

.greyhound-icon::before {
  content: "\E056";
}

.harness-icon::before {
  content: "\E057";
}

.trophy-icon::before {
  content: "\E052";
}

.thumbs-up-icon::before {
  content: "\E01F";
}

.thumbs-down-icon::before {
  content: "\E020";
}

.second-place-icon::before {
  content: "\E099";
}

i {
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
